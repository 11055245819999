import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 10" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 10</h1>
      <p>I dag er jeg 17 uker, weeeeeh!</p>
      <p>Jeg merker at jeg blir større og større, og at jeg kan ha egne meninger. Dette har du sikkert merket at jeg prøver ut både dag og natt, og foreløpig har jeg vært vanskelig med flaska med melk, fordi det merker jeg har stor effekt på deg og pappa. Men bare vent, snart skal jeg slutte med det, og bli vanskelig på andre fronter istedet. Tihihihihih! Jeg er jo en nysgjerrig Litjen, som må prøve ut litt forskjellig, for å utvikle meg!</p>
      <p>Håper du likte sminketingen! Kluten kan du gjerne bruke på meg!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
